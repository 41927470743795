<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h4>Paramètres de profil</h4>
        <div class="p-fluid formgrid grid">
            
            <div class="field col-12 md:col-12"><Message>Le champ du mot de passe n'est pas nécessaire sauf si vous voulez changer votre mot de passe.</Message></div>
          <div class="field col-12 md:col-6">
            <label for="firstname2">Non</label>

            <InputText
              v-model="clientToUpdate.nom"
              type="text"
              :class="{ 'is-invalid': errorsUpdate['nom'] }"
            />
            <small class="p-error" v-if="errorsUpdate['nom']">
              {{ errorsUpdate["nom"] }}
            </small>
          </div>
          <div class="field col-12 md:col-6">
            <label for="lastname2">Prénom </label>
            <InputText
              v-model="clientToUpdate.prenom"
              type="text"
              :class="{ 'is-invalid': errorsUpdate['prenom'] }"
            />
            <small class="p-error" v-if="errorsUpdate['prenom']">
              {{ errorsUpdate["prenom"] }}
            </small>
          </div>
          <div class="field col-12 md:col-6">
            <label for="lastname2">Téléphone </label>
            <InputText
              v-model="clientToUpdate.tele"
              type="text"
              :class="{ 'is-invalid': errorsUpdate['tele'] }"
            />
            <small class="p-error" v-if="errorsUpdate['tele']">
              {{ errorsUpdate["tele"] }}
            </small>
          </div>
          <div class="field col-12 md:col-6">
            <label for="zip">GSM</label>
            <InputText
              v-model="clientToUpdate.gsm"
              type="text"
              :class="{ 'is-invalid': errorsUpdate['gsm'] }"
            />
            <small class="p-error" v-if="errorsUpdate['gsm']">
              {{ errorsUpdate["gsm"] }}
            </small>
          </div>

          <div class="field col-12 md:col-4">
            <label for="city">Email </label>
            <InputText
              v-model="clientToUpdate.email"
              type="text"
              :class="{ 'is-invalid': errorsUpdate['email'] }"
            />
            <small class="p-error" v-if="errorsUpdate['email']">
              {{ errorsUpdate["email"] }}
            </small>
          </div>

          <div class="field col-12 md:col-4">
            <label for="city">Mot de passe </label>
            <InputText
              v-model="clientToUpdate.password"
              type="password"
              :class="{ 'is-invalid': errorsUpdate['password'] }"
            />
            <small class="p-error" v-if="errorsUpdate['password']">
              {{ errorsUpdate["password"] }}
            </small>
          </div>
          <div class="field col-12 md:col-4">
            <label for="city">Confirmation</label>
            <InputText
              v-model="confirmpassword"
              type="password"
              :class="{ 'is-invalid': errorsUpdate['confirmpassword'] }"
            />
            <small class="p-error" v-if="errorsUpdate['confirmpassword']">
              {{ errorsUpdate["confirmpassword"] }}
            </small>
          </div>
          <Button label="Modifier" @click="checkFormUpdate" ></Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Message from 'primevue/message';
export default {
    components: {
    Message,
  },
  data() {
    return {
      clientToUpdate: {
        id: 0,
        prenom: "",
        nom: "",
        tele: "",
        gsm: "",
        email: "",
        password: "",
        role_id:0,
      },
      errorsUpdate: [],
      confirmpassword: "",
    };
  },
  mounted() {
    const user = JSON.parse(localStorage.user);
    this.clientToUpdate.id = user.id;
    this.clientToUpdate.prenom = user.prenom;
    this.clientToUpdate.nom = user.nom;
    this.clientToUpdate.tele = user.tele;
    this.clientToUpdate.gsm = user.gsm;
    this.clientToUpdate.email = user.email;
    this.clientToUpdate.role_id=user.role_id;
  },
  methods: {
          checkFormUpdate() {
      let checked = true;
      this.errorsUpdate = [];
    
      if (this.clientToUpdate.nom == "") {
        this.errorsUpdate["nom"] = "le champ Nom est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.prenom == "") {
        this.errorsUpdate["prenom"] = "le champ Prénom est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.tele == "") {
        this.errorsUpdate["tele"] = "le champ Téléphone est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.gsm == "") {
        this.errorsUpdate["gsm"] = "le champ GSM est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.email == "") {
        this.errorsUpdate["email"] = "le champ Email est obligatoire.";
        checked = false;
      }
      if (this.clientToUpdate.password != "") {
        if (this.clientToUpdate.password.length < 6) {
        this.errorsUpdate["password"] ="le champ mot de passe doit comporter 6 caractères au minimum.";
        checked = false;
      } else if (this.confirmpassword == "") {
        this.errorsUpdate["confirmpassword"] =
          "merci de confirmer votre mot de passe.";
        checked = false;
      } else if (this.confirmpassword != this.clientToUpdate.password) {
        this.errorsUpdate["confirmpassword"] = "confirmation erroné.";
        checked = false;
      }
      } 

      if (checked) {
        this.updateClientserve();
      }
    },
        updateClientserve() {
      axios
        .post("modifierUser/" + this.clientToUpdate.id, this.clientToUpdate)
        .then((response) => {
            console.log(response);
            const user = JSON.parse(localStorage.user);
            user.prenom=this.clientToUpdate.prenom;
            user.nom=this.clientToUpdate.nom;
            user.tele=this.clientToUpdate.tele;
            user.gsm=this.clientToUpdate.gsm;
            user.email=this.clientToUpdate.email;
            localStorage.user = JSON.stringify(user);
            this.$swal({
            icon: "success",
            title: "Votre profil a été bien modifié",
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogOfEdit = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
  },
};
</script>